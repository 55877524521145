import {Component, Input, OnDestroy, OnInit, Optional} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {FormBuilder, Validators, FormGroup} from '@angular/forms';
import {TranslationEventService, TranslationService, DateFormatService, DATE_FORMAT_YMD, Translatable} from '@ngmedax/translation';
import {RegistryService} from '@ngmedax/registry';
import {ConfigService} from '@ngmedax/config';
import {ValueService} from '@ngmedax/value';
import {languages} from '@ngmedax/cloud-translator';
import {LayoutService} from '@ngmedax/layout';
import * as pako from 'pako';
import * as printJS from 'print-js'


import {PatientService} from '../services/patient.service';
import {SurveyService} from '../services/survey.service';
import {ClipboardService} from '../services/clipboard.service';
import {configKeys} from '../patient.config-keys';
import {TRANSLATION_CRUD_SCOPE} from '../../../constants';
import {Patient} from '../../../types';
import {KEYS} from '../../../translation-keys';


declare const $: any;
declare const window: any;

// hack to inject decorator declarations. must occur before class declaration!
export interface PatientCrudComponent extends Translatable {}

@Component({
  selector: 'app-patient-crud',
  templateUrl: './patient-crud.component.html',
  styleUrls: ['./patient-crud.component.css']
})
@Translatable({scope: TRANSLATION_CRUD_SCOPE, keys: KEYS})
export class PatientCrudComponent implements OnInit, OnDestroy  {
  /**
   * Subscriptions
   * @type {Subscription[]}
   */
  private subscriptions: Subscription[] = [];

  /**
   * Should we allow field population via query string?
   * @type {boolean}
   */
  @Input() public allowFormPopulationViaQueryString = false;

  /**
   * Patient form
   * @type {FormGroup}
   */
  public patientForm: FormGroup;

  /**
   * Selected questionnaires
   * @type {string[]}
   */
  public selectedQuestionnaires: string[] = [];

  /**
   * Default locale
   * @type {string}
   */
  public locale = 'de_DE';

  /**
   * Should we hide the patient form?
   * @type {boolean}
   */
  public hideForm = false;

  /**
   * Feature object to determine, what feature is active
   * @type {any}
   */
  public feature: any = {patient: {app: {mail: false, link: {send: false, copy: false, open: false}}, anonymous: false}};

  /**
   * Should we show a button to close the window?
   * @type {boolean}
   */
  public showCloseWinBtn = false;

  /**
   * Is survey mail configured on the server?
   * @type {boolean}
   */
  public isSurveyMailConfigured = false;

  /**
   * Survey Limits
   */
  public surveyLimit = {current: 0, max: 0, reached: false};

  /**
   * Mode for qr code
   */
  public qrMode = 1;

  /**
   * Target for qr code. mobile = mode 1, desktop = mode 2
   */
  public qrTarget = 'mobile';

  /**
   * Current link to start questionnaire via browser
   */
  public qrFillLink = '';

  /**
   * Questionnaires changes since last save or no save until now?
   */
  public dirty = true;

  /**
   * Disable user toggle
   */
  public disableUserToggle = false;

  /**
   * Overlay mode
   */
  public overlayMode: boolean = false;

  /**
   * Languages
   */
  public languages = languages;

  /**
   * Are we in anonymous allocation mode?
   * @type {boolean}
   */
  private anonymousAllocation = false;

  /**
   * Are we in pseudonymous allocation mode?
   * @type {boolean}
   */
  private pseudonymousAllocation = false;

  /**
   * Injects dependencies
   */
  public constructor(
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private patientService: PatientService,
    private surveyService: SurveyService,
    private clipboardService: ClipboardService,
    private configService: ConfigService,
    private valueService: ValueService,
    private registryService: RegistryService,
    private layoutService: LayoutService,
    @Optional() private translationEvents: TranslationEventService,
    @Optional() private translationService: TranslationService,
    @Optional() private dateFormatService: DateFormatService,
  ) {
    if (location.pathname.match(/assign/)) {
      this.hideForm = true;
    }
  }

  public ngOnInit() {
    this.disableUserToggle = !(this.configService.get('feature.patient.new') == true);
    this.translationService && (this.locale = this.translationService.getLocale());
    this.feature.patient = this.configService.get('feature.patient') || this.feature.patient;
    const fb = this.formBuilder;

    this.patientForm = fb.group({
      'uid': fb.control(null),
      'gender': fb.control(null, Validators.required),
      'firstName': fb.control(null, Validators.required),
      'lastName': fb.control(null, Validators.required),
      'zipCode': fb.control(null),
      'city': fb.control(null),
      'street': fb.control(null),
      'streetNr': fb.control(null),
      'address': fb.control(null),
      'birthDate': fb.control(null, Validators.required),
      'location': fb.control(null),
      'email': fb.control(null),
      'allowMail': fb.control('false'),
      'locale': fb.control('de_DE'),
      'prefill': fb.control('no'),
      'status': fb.control(null),
      'pseudoId': fb.control(null),
      'customerNr': fb.control(null, Validators.required),
      'caseNr': fb.control(null),
      'anonymous': fb.control(null),
      'pseudonymous': fb.control(null)
    });

    this.patientService.isSurveyMailConfigured()
      .then(isConfigured => this.isSurveyMailConfigured = isConfigured)
      .catch(error => console.error(error));

    this.translationEvents && this.translationEvents.onLocaleChanged().subscribe(() => {
      const fromLocale = this.locale;
      const toLocale = this.translationService.getLocale();
      const dateFormat = DATE_FORMAT_YMD;
      const date = this.patientForm.get('birthDate').value;
      const converted = this.dateFormatService.convert({fromLocale, toLocale, dateFormat, date});
      this.patientForm.get('birthDate').setValue(converted);
      this.locale = toLocale;
      this.renderDateFields();
    });

    const init = async () => {
      const qPatient = await this.getPatientByQueryParams();

      if (qPatient) {
        this.dirty = false;
        this.renderPatient(qPatient);
      }

      this.activatedRoute.queryParams.subscribe((params: any) => params.closeWinBtn && (this.showCloseWinBtn = true));

      location.pathname.match(/anonymous/) && this.onToggleAnonymous(true);
      location.pathname.match(/pseudonymous/) && this.onTogglePseudonymous(true);

      const routeSubscription = this.activatedRoute.params.subscribe(async (params: any) => {
        if (params.id) {
          await this.loadPatient(params.id, qPatient);
        }
      });

      this.subscriptions.push(routeSubscription);
      this.renderDateFields();

      const license = this.registryService.get('license');

      if (license && license.constraint && license.constraint.numSurveysPerMonth) {
        this.surveyLimit.current = await this.patientService.getNumSubmittedSurveysForCurrentMonth();
        this.surveyLimit.max = license.constraint.numSurveysPerMonth;
        this.surveyLimit.reached = this.surveyLimit.current >= this.surveyLimit.max;
        (<any>this.surveyLimit.current) += '';
      }
    };

    this.qrTarget = this.configService.get('patient.qr.target');
    this.qrTarget && this.qrTarget === 'mobile' && (this.onToggleQr(1));
    this.qrTarget && this.qrTarget === 'browser' && (this.onToggleQr(2));

    init().catch(error => console.error(error));
  }

  /**
   * Unsubscribe from all subscriptions
   */
  public ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  public async onCreateJumpInCode() {
    try {
      const patientFormValues: Patient = this.patientForm.value;
      const patient = await this.patientService.loadPatient(patientFormValues.uid);
      const survey = await this.surveyService.createSurvey(patient, patient.locale || this.locale);
      const surveyCode = await this.surveyService.createSurveyCode(survey.uid);
      const code = surveyCode.code;
      this.clipboardService.copy(code);
      alert(this._(KEYS.CRUD.SUCCESSFULLY_CREATED_JUMP_IN_CODE) + `: ${code}`);
    } catch (error) {
      console.error(error);
      alert(this._(KEYS.CRUD.ERROR_CREATING_JUMP_IN_CODE));
    }

  }

  public onQuestionnairesSelect(questionnaires: string[]) {
    this.selectedQuestionnaires = questionnaires;
    this.dirty = true;
  }

  /**
   * Mobile frontend fill start action
   */
  public async onFillStart() {
    this.patientForm.valid && await this.savePatient();
    const link = this.getMobileFrontendLink();
    window && window.open(link, '_blank');
  }

  /**
   * Copy mobile frontend link to clipboard action
   */
  public async onFillCopyClipboard() {
    this.patientForm.valid && await this.savePatient();
    const link = this.getMobileFrontendLink();
    this.clipboardService.copy(link);
  }

  /**
   * Open default mail app with frontend link in body
   */
  public async onSendMail() {
    this.patientForm.valid && await this.savePatient();
    const dlUrl = `mailto:?subject=myMedax&body=${this.getMobileFrontendLink(true)}`;
    location.href = dlUrl;
  }

  /**
   * Toggles anonymous mode
   */
  public onToggleAnonymous(setTrue = false) {
    this.patientForm.reset();
    this.anonymousAllocation = setTrue ? true : !this.anonymousAllocation;
    this.patientForm.get('anonymous').setValue(this.anonymousAllocation);
    this.patientForm.get('allowMail').setValue('false');
    this.patientForm.get('prefill').setValue('no');
    !this.anonymousAllocation && this.patientForm.get('gender').setValue('male');
  }

  /**
   * Toggles anonymous mode
   */
  public onTogglePseudonymous(setTrue = false) {
    this.patientForm.reset();
    this.pseudonymousAllocation = setTrue ? true : !this.pseudonymousAllocation;
    this.patientForm.get('pseudonymous').setValue(this.pseudonymousAllocation);
    this.patientForm.get('allowMail').setValue('false');
    this.patientForm.get('prefill').setValue('no');
    !this.pseudonymousAllocation && this.patientForm.get('gender').setValue('male');
  }

  /**
   * Toggles qr code
   * @param qrMode
   */
  public onToggleQr(qrMode) {
    this.qrMode = qrMode;
    this.qrFillLink = this.getMobileFrontendLink();
  }

  /**
   * Prints qr code
   */
  public onPrintQr() {
    const canvas = <HTMLCanvasElement>(document.querySelector('.qrcode canvas') || {toDataURL: () => {}});
    printJS({printable: canvas.toDataURL(), type: 'image', base64: true});
  }

  /**
   * Opens qr code
   */
  public onOpenQr() {
    (async () => {
      if (('getScreenDetails' in window)) {
        const permissionStatus = await navigator.permissions.query(<any>{name:'window-management'});
        try {
          await window.getScreenDetails().catch(e =>{ console.error(e); return null; });
        } catch (error) {
        }
      }
    })();


    const canvas = <HTMLCanvasElement>(document.querySelector('.qrcode canvas') || {toDataURL: () => {}});
    const qrWinPos = sessionStorage.getItem('qrWindowPosition') ? JSON.parse(sessionStorage.getItem('qrWindowPosition')) : {};
    const windowFeatures = `width=${qrWinPos.width || 256},height=${qrWinPos.height || 256},left=${qrWinPos.x || 100},top=${qrWinPos.y || 100},menubar=no,toolbar=no,location=no,status=no,resizable=yes,scrollbars=no`;

    canvas.toBlob(blob => {
      const url = URL.createObjectURL(blob);
      const w = window.open(url, '_blank', windowFeatures);

      w.onunload = async () => {
        const width = w.innerWidth;
        const height = w.innerHeight;
        const x = w.screenX;
        const y = w.screenY;
        console.log('window closed', {width, height, x, y, screen: w.screen});
        sessionStorage.setItem('qrWindowPosition', JSON.stringify({width, height, x, y}));
      };

    }, 'image/png');
  }

  /**
   * Form submit action. Saves patient
   */
  public onSubmit() {
    this
      .savePatient()
      .then(() => setTimeout(() => this.selectedQuestionnaires.length && !this.overlayMode && alert(this._(KEYS.CRUD.SAVED_QR_CODE_HINT)), 700))
      .catch(error => console.log(error));
  }

  /**
   * Loads patient by id or query params
   */
  public async loadPatient(patientId: string, qPatient: any) {
    try {
      this.layoutService.showPreloader();
      const patient = await this.patientService.loadPatient(patientId);

      if (!patient) {
        alert(this._(KEYS.CRUD.FOUND_NO_PATIENT_BY_ID) + ' ' + patientId);
        return;
      }

      this.dirty = false;
      this.renderPatient(patient);

      if (qPatient) {
        this.renderPatient(qPatient);
      }
      this.layoutService.hidePreloader();
    } catch (error) {
      console.error(error);
      this.layoutService.hidePreloader();
      alert(this._(KEYS.CRUD.ERROR_LOADING_PATIENT));
    }
  }

  /**
   * Returns ymd date format. E.g: MM/DD/YYYY
   *
   * @returns {string}
   */
  public getLocalDateFormat(): string {
    return this.getDateFormat(DATE_FORMAT_YMD);
  }

  /**
   * Returns date format mask for ymd date format. E.g: 00/00/0000
   *
   * @returns {string}
   */
  public getLocalDateFormatMask() {
    return this.getLocalDateFormat().replace(/[YMD]/g, '0');
  }

  /**
   * Converts local ymd date to mysql date format
   *
   * @param {string} date
   * @returns {string}
   */
  public toMySqlDate(date: string): string {
    return this.dateFormatService ? this.dateFormatService.toMySqlDate(date, this.getLocalDateFormat()) : date;
  }

  /**
   * Converts mysql date to local ymd date
   *
   * @param {string} date
   * @returns {string}
   */
  public fromMySQLDate(date): string {
    return this.dateFormatService ? this.dateFormatService.fromMySqlDate(date, this.getLocalDateFormat()) : date;
  }

  /**
   * Builds and returns direct link to mobile frontend for current patient
   *
   * @param {boolean} encodeUri
   * @returns {string}
   */
  public getMobileFrontendLink(encodeUri: boolean = false): string {
    const mobileApiUri = this.configService.get(configKeys.MOBILE_URI_CONFIG_KEY);
    const mobileFrontendUri = this.configService.get(configKeys.MOBILE_FRONTEND_URI_CONFIG_KEY) || '/app';

    const pairCode = `mymedax|serverurl|${mobileApiUri}`;
    const assignCode = `mymedax|patient|${this.patientForm.get('uid').value}`;

    const locale = this.patientForm.get('locale').value || this.locale;
    let baseUrl = (mobileFrontendUri.match(/\/$/) ? mobileFrontendUri : mobileFrontendUri + '/');
    !baseUrl.match(/^htt/) && (baseUrl = `${window.location.protocol}//${window.location.host}${baseUrl}`);
    const queryString = `autoPairMode=true&pairCode=${pairCode}&assignCode=${assignCode}&locale=${locale}`;

    const encode = encodeUri ? encodeURIComponent : (u) => u;

    if (window && window.btoa) {
      try {
        const enc = queryString.split('').reduce((all, str) => all += String.fromCharCode(23 ^ str.charCodeAt(0)), '');
        const gzipped = pako.deflate(enc, {to: 'string'});
        const base64 = window.btoa(gzipped);
        return encode(`${baseUrl}?c=${base64}`);
      } catch (error) {
        // we can ignore this
      }
    }

    return encode(`${baseUrl}?${queryString}`);
  }

  public objectKeys(obj) {
    return Object.keys(obj);
  }

  public translateLanguage(language: string): string {
    return this.translationService ?
      this.translationService.translate({
        text: language,
        scope: ['cloudTranslatorModule', 'default']
      }) : language;
  }

  private async savePatient() {
    try {
      const patient: Patient = this.patientForm.value;

      patient.allowMail && (<any>patient.allowMail) === 'true' && (patient.allowMail = true)
      patient.prefill && (<any>patient.prefill) === 'no' && (patient.prefill = false) && (patient.prefillOptional = false);
      patient.prefill && (<any>patient.prefill) === 'yes' && (patient.prefill = true) && (patient.prefillOptional = false);
      patient.prefill && (<any>patient.prefill) === 'optional' && (patient.prefill = true) && (patient.prefillOptional = true);
      patient.birthDate && (patient.birthDate = this.toMySqlDate(patient.birthDate));

      const {uid} = await this.patientService.savePatient(patient);
      let savedPatient = await this.patientService.loadPatient(uid);
      savedPatient.birthDate = patient.birthDate;

      // if (this.selectedQuestionnaires.length) {
        await this.patientService.assignQuestionnaires(savedPatient, this.selectedQuestionnaires);
        savedPatient = await this.patientService.loadPatient(savedPatient.uid);
        this.dirty = false;
      // }

      this.renderPatient(savedPatient);
      alert(this._(KEYS.CRUD.SUCCESSFULLY_SAVED_PATIENT));
    } catch (error) {
      console.log(error);
      alert(this._(KEYS.CRUD.ERROR_SAVING_PATIENT));
    }
  };

  /**
   * Renders patient into form
   *
   * @param {Patient} patient
   */
  private renderPatient(patient: Patient) {
    Object.keys(patient).forEach(prop => {
      if (this.patientForm.contains(prop)) {
        let value = patient[prop];

        if (prop === 'birthDate') {
          // mysql date format
          if (value.match(/([0-9]{4})-([0-9]{2})-([0-9]{2})/)) {
            value = this.fromMySQLDate(value);
          }
          // german date format. maybe from query string
          if (value.match(/([0-9]{2})\.([0-9]{2})\.([0-9]{4})/) && this.locale !== 'de_DE') {
            const opts = {fromLocale: 'de_DE', toLocale: this.locale, dateFormat: DATE_FORMAT_YMD, date: value};
            this.dateFormatService && (value = this.dateFormatService.convert(opts));
          }
        }

        if (prop == 'prefill' && value == true) {
          value = patient.prefillOptional ? "optional" : "yes";
        }

        if (prop == 'prefill' && value == false) {
          value = "no";
        }

        this.patientForm.get(prop).setValue(value);
      }
    });

    if (Array.isArray(patient.questionnaires)) {
      this.selectedQuestionnaires = patient.questionnaires.map(q => q.id);
    }
  }

  /**
   * Returns patient by query string params
   * @returns {Patient}
   */
  private async getPatientByQueryParams(): Promise<Patient> {
    const queryParams = this.activatedRoute.snapshot && this.activatedRoute.snapshot.queryParams ?
      this.activatedRoute.snapshot.queryParams : null;

    if (!queryParams || (queryParams.id || queryParams.uid)) {
      return;
    }

    // convert to flat object
    const params = this.clone(queryParams);

    // locale in query string? set it!
    if (params.locale && this.translationService) {
      await new Promise<void>((resolve) => setTimeout(() => resolve(), 10));
      this.translationService.setLocale(params.locale);
    }

    return queryParams !== null && Object.keys(queryParams).length !== 0 ? <Patient>this.clone(queryParams) : null;
  }

  /**
   * Renders date fields
   */
  private renderDateFields() {
    if (typeof $ === 'function' && $('').mask) {
      $('#birthDate').mask(this.getLocalDateFormatMask(), {placeholder: this.getLocalDateFormat()});
    }
  }

  private clone(obj): any {
    return JSON.parse(JSON.stringify(obj));
  }
}
