<div class="qa-choice-display-type input-group mb-1" *ngIf="(question.displayType && (question.displayType == 'radio' || question.displayType == 'dropdown'))">
  <span class="input-group-addon">{{_(KEYS.EDITOR.DISPLAY_TYPE)}}</span>
  <select class="form-control qa-custom-select" [(ngModel)]="question.displayType">
    <option value="radio">{{_(KEYS.EDITOR.RADIO_BUTTONS)}}</option>
    <option value="dropdown">{{_(KEYS.EDITOR.DROPDOWN)}}</option>
  </select>
</div>
<div *ngFor="let answer of question.elements; let position = index;">
  <div class="input-group mb-1">
    <button class="btn btn-success qa-input-position mr-1">
      {{position + 1}}
    </button>

    <div class="btn-group ml-1 mr-2">
      <button class="btn btn-primary" (click)="onPositionChange(position, position - 1)">
        <i class="fa fa-arrow-up"></i>
      </button>

      <button class="btn btn-primary" (click)="onPositionChange(position, position + 1)">
        <i class="fa fa-arrow-down"></i>
      </button>
    </div>

    <button class="btn btn-secondary mr-2">
      <ng-container *ngIf="(answer.displayType && answer.displayType === 'radio') || (!answer.displayType && question.displayType === 'radio')">
        <i class="fa fa-dot-circle-o" placement="left"></i>
      </ng-container>
      <ng-container *ngIf="(answer.displayType && answer.displayType === 'dropdown') || (!answer.displayType && question.displayType === 'dropdown')">
        <i class="fa fa-chevron-circle-down" placement="left"></i>
      </ng-container>
      <ng-container *ngIf="(answer.displayType && answer.displayType === 'checkbox') || (!answer.displayType && question.displayType === 'checkbox')">
        <i class="fa fa-check-square-o" placement="left"></i>
      </ng-container>
      <ng-container *ngIf="(answer.displayType && answer.displayType === 'text') || (!answer.displayType && question.displayType === 'text')">
        <i class="fa fa-pencil" placement="left"></i>
      </ng-container>
    </button>

    <div class="qa-question-title qa-fake-input">
      <div class="qa-mt-04" [froalaEditor]="answerEditorOptions" [(froalaModel)]="answer.title[locale]" (blur)="updateElement()"></div>
    </div>

    <button
      class="btn btn-secondary ml-2"
      (click)="onOpenMediaCenterModal('audio', answer)"
      *ngIf="!answer.assets || !answer.assets.audio || !answer.assets.audio.length"
    >
      <i class="fa fa-play"></i>
    </button>

    <div ngbDropdown class="d-inline-block qa-no-arrow ml-2" *ngIf="answer?.assets?.audio?.length">
      <button class="btn btn-primary"  ngbDropdownToggle>
        <i class="fa fa-play"></i>
      </button>
      <div ngbDropdownMenu>
        <button class="dropdown-item" (click)="onOpenMediaCenterModal('audio', answer)">
          <i class="fa fa-edit"></i> {{_(KEYS.EDITOR.EDIT)}}
        </button>
        <button class="dropdown-item" (click)="onDeleteAnswerMedia('audio', answer)">
          <i class="fa fa-trash"></i> {{_(KEYS.EDITOR.REMOVE)}}
        </button>
      </div>
    </div>

    <button
      class="btn btn-primary ml-2"
      [ngClass]="{'btn-secondary': !answer.conditions || !answer.conditions.mark, 'btn-primary': answer.conditions && answer.conditions.mark}"
      ngbTooltip="{{_(KEYS.EDITOR.MARK)}}"
      (click)="onToggleMark(answer)"
    >
      <i class="fa fa-bookmark"></i>
    </button>

    <button class="btn btn-primary ml-2" ngbTooltip="{{_(KEYS.EDITOR.SCORING)}}" (click)="onScoringModal(position)">
      <i class="fa fa-sort-numeric-asc"></i>
    </button>

    <button class="btn btn-primary ml-2" ngbTooltip="Variablen" (click)="onVariablesModal(position)">
      <i class="fa fa-code"></i>
    </button>

    <button
      class="btn btn-danger ml-2"
      (click)="onDeleteAnswer(position)"
      [attr.disabled]="(answer.undeletable)? true: null"
    >
      <i class="fa fa-trash"></i>
    </button>
  </div>
</div>
<div class="btn-group mt-2">
  <button class="btn btn-sm btn-success" (click)="onAddAnswer()">
    <i class="fa fa-plus"></i> {{_(KEYS.EDITOR.ADD_ANSWER)}}
  </button>
</div>
