export const NAVIGATION = {
  name: 'Benutzer',
  icon: 'fa-users',
  path: '/module/ums/',
  children: [{
    name: 'Fachgruppen',
    path: '/module/ums/filtergroups',
    icon: 'fa-filter'
  }]
};
